/* If you need to add @import statements, do so up here */
@import './editordarkmode.css';
@import "jit-refresh.css"; /* triggers frontend rebuilds */

@layer base {
  :root {
    --shiki-color-text: theme('colors.white');
    --shiki-token-constant: theme('colors.emerald.300');
    --shiki-token-string: theme('colors.emerald.300');
    --shiki-token-comment: theme('colors.zinc.500');
    --shiki-token-keyword: theme('colors.sky.300');
    --shiki-token-parameter: theme('colors.pink.300');
    --shiki-token-function: theme('colors.violet.300');
    --shiki-token-string-expression: theme('colors.emerald.300');
    --shiki-token-punctuation: theme('colors.zinc.200');
  }

  [inert] ::-webkit-scrollbar {
    display: none;
  }
}

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;

.prose h4 {
  font-weight: 600;
}

button.DocSearch.DocSearch-Button {
  @apply h-8 w-full items-center gap-2 rounded-full bg-white pl-2 pr-3 text-sm text-zinc-500 ring-1 ring-zinc-900/10;
  @apply transition hover:ring-zinc-900/20 lg:flex dark:bg-white/5 dark:text-zinc-400 dark:ring-inset dark:ring-white/10 dark:hover:ring-white/20;

}

#oba-docs-search-container--mobile button.DocSearch-Button {
  margin: 0;
}

.DocSearch-Search-Icon {
  @apply !h-5 !w-5 !text-zinc-400 dark:text-zinc-500;
}

.DocSearch-Button-Placeholder {
  @apply !text-sm !text-zinc-400 dark:text-zinc-500;
}

.DocSearch-Button-Keys {
  @apply ml-auto text-2xs text-zinc-400 dark:text-zinc-500 inline-block text-right;

}

.DocSearch-Button-Key {
  background: none;
  box-shadow: none;
  @apply font-sans m-0 p-0 w-auto inline-block;
}

pre.highlight {
  background-color: transparent!important;
  padding: 0!important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply dark:bg-zinc-800 bg-[#f3f3f3];
}

::-webkit-scrollbar-thumb {
  @apply dark:bg-zinc-600 bg-[#c2c2c2];
}

::-webkit-scrollbar-thumb:hover {
  @apply dark:bg-zinc-400 bg-zinc-400;
}

::-webkit-scrollbar-thumb:active {
  @apply dark:bg-zinc-200 bg-zinc-500;
}

@tailwind utilities;

/* Prevent headings from getting hidden when directly accessing sections with ids: */
:target:before {
    content: "";
    display: block;
    height: 80px;
    margin-top: -80px; 
}

